import { useState, useEffect } from "react";
import getWindow from "../utils/window";

const useLocalStorage = (storageKey, fallbackState) => {
  const [value, setValue] = useState(
    JSON.parse(getWindow().localStorage.getItem(storageKey) ?? fallbackState)
  );

  useEffect(() => {
    getWindow().localStorage.setItem(storageKey, value);
  }, [value, storageKey]);

  return [value, setValue];
};

export default useLocalStorage;
