import SaveForLaterPortal from "../saveForLaterPortal/SaveForLaterPortal";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import FitAssistantTooltip from "../../components/fitAssistantTooltip/FitAssistantTooltip";
import SaveCount from "../../components/saveCount/SaveCount";
import LooksButton from "../../components/looks/LooksButton";
import MixAndMatchPaymentPromoContainer from "../mixAndMatchPaymentPromoContainer/MixAndMatchPaymentPromoContainer";
import MixAndMatchRatingsContainer from "../mixAndMatchRatingsContainer/mixAndMatchRatingsContainer";
import { useStockPrice } from "../../context/stockPrice/StockPriceContext";

export default function ReactPortals() {
  const { products } = useStockPrice();
  const { isMixAndMatch } = useRuntimeConstants();

  const renderMixAndMatchSaveButton = () => {
    return products.products
      .filter(({ variants }) => variants.length)
      .map((productItem) => (
        <SaveForLaterPortal
          product={productItem}
          key={`save-product-${productItem.id}`}
        />
      ));
  };

  const renderMainSaveButton = () => {
    if (products) {
      return isMixAndMatch && renderMixAndMatchSaveButton();
    }
  };

  return (
    <>
      <SaveCount />
      <FitAssistantTooltip />
      <MixAndMatchPaymentPromoContainer />
      <MixAndMatchRatingsContainer />
      <LooksButton />
      {renderMainSaveButton()}
    </>
  );
}
