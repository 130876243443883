import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import styles from "./Tooltip.scss";
import useEventListener from "../../hooks/useEventListener";
import { PropTypes } from "prop-types";

const Tooltip = ({ children, hide = false, hasBeenClosedCallback }) => {
  const [hideTooltip, setHideTooltip] = useState(hide);
  const { getTranslation } = useRuntimeConstants();
  const [focusOnClose, setFocusOnClose] = useState(null);
  const wrapperRef = useRef();
  const closeButtonRef = useRef();

  const closeTooltip = () => {
    focusOnClose && focusOnClose.focus();
    hasBeenClosedCallback && hasBeenClosedCallback();
    setHideTooltip(true);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 27 || event.key === "escape") {
      closeTooltip();
    }
  };

  const handleMouseDown = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      closeTooltip();
    }
  };

  useEventListener("keydown", handleKeyDown, []);

  useEventListener("mousedown", handleMouseDown, []);

  useEffect(() => {
    setHideTooltip(hide);
  }, [hide]);

  useLayoutEffect(() => {
    setFocusOnClose(document.activeElement);

    closeButtonRef.current && closeButtonRef.current.focus();
  }, [hideTooltip]);

  return (
    !hideTooltip && (
      <div
        ref={wrapperRef}
        className={styles.tooltipWrapper}
        aria-live={"polite"}
        data-testid="tooltip"
      >
        <div className={styles.tooltip}>
          <div className={styles.tooltipContent}>{children}</div>
          <div className={styles.tooltipClose}>
            <button
              ref={closeButtonRef}
              className={styles.close}
              aria-label={getTranslation("pdp_misc_close")}
              onClick={closeTooltip}
            >
              <div className={styles.icon} />
            </button>
          </div>
        </div>
      </div>
    )
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  hide: PropTypes.bool,
  hasBeenClosedCallback: PropTypes.func,
};

export default Tooltip;
