import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import useElementWhenReady from "../../hooks/useElementWhenReady";
import Tooltip from "../tooltip/Tooltip";
import useLocalStorage from "../../hooks/useLocalStorage";
import { useRuntimeConstants } from "../../context/runtimeConstants/RuntimeConstantsContext";
import { useFeaturesReady } from "../../context/featuresReady/FeaturesReadyContext";
import { getFeature } from "../../utils/features";
import styles from "./FitAssistantTooltip.scss";

export const DEFAULT_TIME_UNTIL_SHOW = 5000;

const FitAssistantTooltip = () => {
  const { areFeaturesReady } = useFeaturesReady();
  const portalElement = useElementWhenReady(`#fit-assistant-tooltip`);
  const [showFitAssistantTooltip, setShowFitAssistantTooltip] = useState(false);
  const [hasBeenViewed, setHasBeenViewed] = useState(false);
  const [storageHasBeenViewed, setStorageHasBeenViewed] = useLocalStorage(
    "toolTip-hasBeenViewed",
    false
  );
  const { getTranslation } = useRuntimeConstants();
  const [timeUntilShown, setTimeUntilShown] = useState(0);

  useEffect(() => {
    setHasBeenViewed(storageHasBeenViewed);
  }, []);

  const shouldShowTooltip = () =>
    portalElement &&
    areFeaturesReady &&
    timeUntilShown &&
    !storageHasBeenViewed &&
    getFeature("pdp-fit-analytics-engagement-booster").isEnabled();

  useEffect(() => {
    if (shouldShowTooltip()) {
      setTimeout(() => {
        setStorageHasBeenViewed(true);
        setShowFitAssistantTooltip(true);
      }, timeUntilShown);
    }
  }, [portalElement, areFeaturesReady, timeUntilShown]);

  useEffect(() => {
    if (areFeaturesReady) {
      setTimeUntilShown(
        getFeature("pdp-fit-analytics-engagement-booster").getVariables()
          ?.tooltipAnimationSeconds * 1000 || DEFAULT_TIME_UNTIL_SHOW
      );
    }
  }, [areFeaturesReady]);

  if (!portalElement || hasBeenViewed) {
    return null;
  }

  return ReactDOM.createPortal(
    <Tooltip
      hasBeenClosedCallback={() => setStorageHasBeenViewed(true)}
      hide={!showFitAssistantTooltip}
    >
      <div className={styles.fitAssistantTooltipWrapper}>
        <div className={styles.iconWrapper}>
          <div className={styles.circle} aria-hidden={true}>
            <span className={"no-recommendation"} role="img" />
          </div>
        </div>
        <div className={styles.content}>
          <h3 className={styles.header}>
            {getTranslation("pdp_fit_analytics_engagementbooster_title")}
          </h3>
          <p className={styles.content}>
            {getTranslation("pdp_fit_analytics_engagementbooster_body")}
          </p>
        </div>
      </div>
    </Tooltip>,
    portalElement
  );
};

export default FitAssistantTooltip;
